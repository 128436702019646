//我的设备
<template>
  <Page>
    <el-tabs slot="tabs" v-model="params.status" @tab-click="handleClick">
      <el-tab-pane label="借调设备" :value="0"></el-tab-pane>
      <el-tab-pane label="已销售设备" :value="1"></el-tab-pane>
      <el-tab-pane label="在途" :value="1"></el-tab-pane>
    </el-tabs>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="设备名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item label="料号" prop="code">
          <el-input placeholder="请输入" v-model="params.code"></el-input>
        </el-form-item>
        <el-form-item label="申请单号" prop="orderCode">
          <el-input placeholder="请输入" v-model="params.orderCode"></el-input>
        </el-form-item>
      </el-form>
    </template>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="code"
          label="料号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="name"
          label="设备名称"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="orderCode"
          label="关联申请单号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="申请时间"
        >
          <template slot-scope="{ row }">
            {{ row.applyTime | date('YYYY-mm-dd HH:MM:SS') }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="70"
        >
          <template slot-scope="{ row }">
            <el-button size="medium" type="text" @click="toDetail(row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      params: { status: 0 }
    }
  },
  methods: {
    handleClick() {
      this.params.pageNo = 1
      this.$nextTick(() => {
        this.getData()
      })
    },
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        params.pageNum = params.pageNo
        //transitStatus是否在途 0否 1是
        if (params.status == '0') {
          params.type = 0
          params.transitStatus = 0
        } else if (params.status == '1') {
          params.type = 1
          params.transitStatus = 0
        } else if (params.status == '2') {
          params.transitStatus = 1
          params.type = ''
        }
        delete params.status
        delete params.pageNo
        const { list, total } = await this.$api.apply.getMyEquipment(params)
        this.tableData = list || []
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    toDetail(row) {
      this.$router.push({
        path: '/myAudit/detail',
        query: {
          processCode: row.orderCode,
          type: row.orderType,
		  from: 'myDevice'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
